import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Craig Fan', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Craig Fan', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'I\'m',
  name: 'Craig Fan',
  subtitle: 'And I like building ideas into Products',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'me.jpg',
  paragraphOne: 'I\'m a software engineer at Tinder. I graduated from UMass Amherst with a BS in computer science. I\'ve also graduated from the University of Pennsylvania with a MS in computer science.',
  paragraphTwo: 'Even though I\'m an engineer, I love sociology and seeing how people interact. It\'s one of my dreams to become self-sustainable so that I can work on software that helps bring people together at a deeper level',
  paragraphThree: 'My areas of interest are software engineering, distributed systems, scalable software, and machine learning. If I\'m away from the computer, I love playing tennis and having my friends drag me to restaurants I\'ve never eaten at',
  resume: 'https://drive.google.com/file/d/17L1p_E82d51tIJ7tvwJW_hdOvHQi_Cxy/view?usp=sharing', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'tinder.jpg',
    title: 'Software Engineer at Tinder',
    info: 'Working on recommendations team',
    info2: '',
  },
  {
    id: nanoid(),
    img: 'tripactions.jpg',
    title: 'Ex-Software Engineer at TripActions',
    info: 'Worked on core products to create the best traveler experience',
    info2: '',
  },
  {
    id: nanoid(),
    img: 'pandopooling.jpg',
    title: 'Data Science Intern at Pando Pooling',
    info: 'Interned at a PearVC funded startup to gauge career earnings of NFL wide receivers',
    info2: '',
    url: '',
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Let\'s grab coffee',
  btn: 'Hit me up!',
  email: 'fancraig96@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/craig-fan/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/CraigFan96',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
